import { NextSeo, NextSeoProps } from 'next-seo'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const url = process.env.NEXT_PUBLIC_URL

export const defaultSEO: NextSeoProps = {
  title: 'Top Servers',
  description: 'Best private servers and games you find here!',
  additionalMetaTags: [
    { name: 'application-name', content: 'Top Servers' },
    { name: 'apple-mobile-web-app-title', content: 'Top Servers' },
    { name: 'msapplication-TileColor', content: '#603cba' },
    { name: 'theme-color', content: '#101116' },
    {
      name: 'msapplication-config',
      content: `${url}/assets/favicon/browserconfig.xml`
    }
  ],
  additionalLinkTags: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: `${url}/assets/favicon/apple-touch-icon.png`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: `${url}/assets/favicon/favicon-32x32.png`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: `${url}/assets/favicon/favicon-16x16.png`
    },
    {
      rel: 'manifest',
      href: `${url}/assets/favicon/site.webmanifest`
    },
    {
      rel: 'mask-icon',
      color: '#9580ff',
      href: `${url}/assets/favicon/safari-pinned-tab.svg`
    },
    {
      rel: 'shortcut icon',
      href: `${url}/assets/favicon/favicon.ico`
    }
  ],
  openGraph: {
    site_name: 'Top Servers',
    images: [
      {
        url: `${url}/assets/logo/cover.png`,
        width: 1200,
        height: 628,
        alt: 'Top Servers'
      },
      {
        url: `${url}/assets/favicon/android-chrome-256x256.png`,
        width: 256,
        height: 256,
        alt: 'Top Servers'
      }
    ]
  }
}

export function TopServersSEO(data?: NextSeoProps) {
  const { t } = useTranslation()

  const newData = useMemo(() => {
    // eslint-disable-next-line react/destructuring-assignment
    if (data && (!data?.title || data?.title === 'Top Servers')) {
      const { openGraph, additionalMetaTags, additionalLinkTags } = data
      return {
        ...data,
        openGraph: { ...defaultSEO.openGraph, ...openGraph },
        additionalMetaTags: [
          ...(defaultSEO.additionalMetaTags || []),
          ...(additionalMetaTags || [])
        ],
        additionalLinkTags: [
          ...(defaultSEO.additionalLinkTags || []),
          ...(additionalLinkTags || [])
        ],
        titleTemplate: undefined,
        title: 'Top Servers'
      }
    }
    return data
  }, [data])

  return (
    <NextSeo {...defaultSEO} description={t('seo.description')} {...newData} />
  )
}
