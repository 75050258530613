/* eslint-disable react/no-danger */
import parseHtml from 'html-react-parser'
import { GetServerSideProps } from 'next'
import { NextSeo } from 'next-seo'
import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'

import { defaultSEO } from 'helpers/defaultSEO'
import { getClientIp } from 'helpers/getClientIp'
import { retryCatch } from 'helpers/retryCatch'

import { setupAPIClient } from 'services/api'
import { IGameListResponse } from 'services/api/models/Games'

import styles from './index.module.scss'
import { HomeTexts } from './index.texts'

interface IHomeProps {
  servers: number
  games: IGameListResponse[]
}

export default function Home({ servers, games }: IHomeProps) {
  const texts = HomeTexts()
  const { t } = useTranslation()

  return (
    <>
      <NextSeo
        {...defaultSEO}
        title="Top Servers"
        description={t('seo.description')}
        openGraph={{
          description: t('seo.description'),
          site_name: `Top Servers`,
          title: `Top Servers`,
          url: process.browser ? window?.location.href : undefined
        }}
      />
      <div className={`${styles.wrapper}`}>
        {/* Hero */}
        <div className={styles.hero}>
          <div className={`${styles.container} container`}>
            <h1>{texts.title}</h1>
            <h3 className="grid-10">{texts.description}</h3>
            <Button as="link" href="/servers">
              {texts.browseButton}
            </Button>
          </div>
          <div className={styles.heroBackground}>
            <Image
              src="/assets/images/home-hero.png"
              alt="Hero background"
              title=""
              layout="fill"
            />
          </div>
        </div>
        {/* Best of the month */}
        <div className={`${styles.bestOfTheMonth} container`}>
          <div className={styles.header}>
            <h3>{texts.bestGames.title}</h3>
            <p>{texts.bestGames.description}</p>
          </div>
          <div className={styles.bestGamesGrid}>
            {games.map(game => (
              <div key={game.id}>
                <h3>{game.name}</h3>
                <small>{game.category.name}</small>
                <div className={styles.gridBg}>
                  <Image
                    src={game.cover_url}
                    alt={game.name}
                    title={game.name}
                    layout="fill"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Publish your own server */}
        <div className={`${styles.publishYourOwn} container`}>
          <div className={styles['ellipse-1']} />
          <div className={styles['ellipse-2']} />
          <div className={styles['ellipse-3']} />
          <h3>{texts.publishYourOwn.title}</h3>
          <div className="grid-8">
            {parseHtml(texts.publishYourOwn.description(servers))}
          </div>
          <Button outline as="link" href="/account/servers">
            {texts.publishYourOwn.button}
          </Button>
        </div>
      </div>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ctx => {
  try {
    const api = setupAPIClient(ctx)
    getClientIp(ctx.req, api)
    const serversRequest = () => api.get<number>(`/servers/total`)
    type ServersRequestReturn = ReturnType<typeof serversRequest>
    const { data: servers } = await retryCatch<ServersRequestReturn>(
      serversRequest
    )

    const topGamesRequest = () => api.get('/games/top')
    type GamesRequestReturn = ReturnType<typeof topGamesRequest>
    const { data: games } = await retryCatch<GamesRequestReturn>(
      topGamesRequest
    )

    return { props: { servers, games } }
  } catch (error) {
    return { props: { servers: 0, games: [] } }
  }
}
