import { useTranslation } from 'react-i18next'

export function HomeTexts() {
  const { t } = useTranslation()

  return {
    title: t('pHome.title'),
    description: t('pHome.description'),
    browseButton: t('pHome.browseButton'),
    bestGames: {
      title: t('pHome.bestGames.title'),
      description: t('pHome.bestGames.description')
    },
    publishYourOwn: {
      title: t('pHome.publishOwn.title'),
      description: (count: number) =>
        t('pHome.publishOwn.description', { count }),
      button: t('pHome.publishOwn.button')
    }
  }
}
